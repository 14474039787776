<template>
  <v-container>
    <form
      action
      data-vv-scope="consent-form-new"
      @submit.prevent="validateForm('consent-form-new')"
    >
      <v-row style="display: flex; width: 100%">
        <draggable
          style="width: 100%"
          :disabled="!edit"
          v-model="section.modules"
          ghost-class="ghost"
        >
          <v-col
            cols="12"
            v-for="(modules, i) in section.modules"
            :key="i"
            :style="`display: inline-block !important`"
            :md="modules.size"
            class="pa-0 columnEdit"
          >
            <div
              id="editColumn"
              :style="
                modules.type == 'espacio' ? 'position:absolute;z-index:4' : ''
              "
              v-if="edit"
            >
              <v-menu
                bottom
                left
                offset-y
                origin="top right"
                transition="scale-transition"
                style="width: 100px !important"
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn min-width="0" fab text v-on="on" small>
                    <v-icon small> $edit</v-icon>
                  </v-btn>
                </template>
                <v-list v-for="(size, i) in sizes" :key="i">
                  <v-row @click="modules.size = size.value"
                    ><v-list-item-title
                      class="px-5"
                      style="cursor: pointer"
                      v-text="size.text"
                  /></v-row>
                </v-list>
              </v-menu>
            </div>
            <v-col class="pa-3" v-if="modules.type != 'espacio'">
              <label
                v-text="modules.title"
                v-if="modules.type != 'check'"
              ></label>
              <v-text-field
                outlined
                dense
                :disabled="edit"
                v-if="modules.type == 'texto'"
                data-vv-validate-on="blur"
                :v-validate="modules.required ? 'required' : ''"
                :error-messages="
                  errors.collect(`consent-form-new.${modules.title}`)
                "
                :data-vv-name="modules.title"
                color="secondary"
                :v-model="!edit ? form[modules.title] : ''"
              ></v-text-field>

              <v-textarea
                v-if="modules.type == 'texto largo'"
                style="border-radius: 10px"
                outlined
                data-vv-validate-on="blur"
                :v-validate="modules.required ? 'required' : ''"
                :error-messages="
                  errors.collect(`consent-form-new.${modules.title}`)
                "
                :v-model="!edit ? form[modules.title] : ''"
                :data-vv-name="modules.title"
                :disabled="edit"
                rows="2"
              >
              </v-textarea>
              <v-checkbox
                class="check1"
                :v-validate="modules.required ? 'required' : ''"
                :error-messages="
                  errors.collect(`consent-form-new.${modules.title}`)
                "
                :data-vv-name="modules.title"
                :disabled="edit"
                v-if="modules.type == 'check'"
                :v-model="!edit ? form[modules.title] : ''"
              >
                <template v-slot:label="">
                  <span v-text="modules.title"></span>
                </template>
              </v-checkbox>

              <v-select
                v-if="modules.type == 'select'"
                :items="modules.option"
                single-line
                dense
                outlined
                :v-model="!edit ? form[modules.title] : ''"
                :v-validate="modules.required ? 'required' : ''"
                :error-messages="
                  errors.collect(`consent-form-new.${modules.title}`)
                "
                :data-vv-name="modules.title"
                hide-details
              ></v-select>

              <v-menu
                v-if="modules.type == 'fecha'"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    outlined
                    dense
                    :v-validate="modules.required ? 'required' : ''"
                    :error-messages="
                      errors.collect(`consent-form-new.${modules.title}`)
                    "
                    :data-vv-name="modules.title"
                    :value="date ? $d(new Date(date), 'date2digits') : ''"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><template v-slot:prepend-inner>
                      <!--Aqui va el v-icon -->
                      <v-icon
                        small
                        class="pt-0 pb-1"
                        style="margin-top: 6px"
                        color="primary"
                        >$appointments</v-icon
                      >
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  :v-model="!edit ? form[modules.title] : ''"
                  no-title
                  scrollable
                  color="primary"
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="modules.type == 'espacio'"
              :style="
                edit
                  ? ' background-color: var(--v-gris1-base); opacity: 0.5;width: 100%;height: 60px;margin-bottom: -16px;'
                  : 'width: 100%;height: 60px;margin-bottom: -16px;'
              "
            ></v-col>
          </v-col>
        </draggable>
      </v-row>
      <v-row class="pa-3" justify="end" v-if="!edit">
        <v-btn
          color="primary"
          @click="$emit('back')"
          style="height: 30px"
          outlined
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("back") }}
        </v-btn>

        <v-btn
          color="primary"
          type="submit"
          style="
            width: 130px !important;
            color: #363533;
            height: 30px;
            padding-top: 10px !important;
            margin-left: 10px;
          "
          elevation="0"
        >
          <v-icon small style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "VisualConsentForm",
  components: {
    draggable,
  },
  props: ["section", "sizes", "edit", "form"],
  data() {
    return {
      menu: false,
      date: null,
      dialog: false,
    };
  },
  mounted() {
    //console.log(this.section.modules);
  },
  methods: {
    validateForm(scope) {
      //console.log("validando");
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        //console.log(result, this.errors);
        if (result) {
          ////console.log(data);
          this.$emit("validated");
          //console.log("validado");
          //return true;
        } else {
          //return false;
        }
      });
    },
  },
};
</script>

<style lang="sass">
.check1
  margin-top: 10px !important
.v-input--selection-controls__input
  margin-top: 0px !important
.columnEdit:hover
  #editColumn
    display: block
    position: relative
    margin: 0
    padding: 0
    margin-bottom: -50px
    margin-left: -10px

#editColumn
  display: none
</style>